<template>
  <div class="content flex">
    <div class="edit">
      <el-form :model="edit" :rules="rules" ref="ruleForm" class="tb-form" label-width="140px" label-position="right">
        <el-form-item label="活动名称：" prop="name">
          <el-input v-model="edit.name" placeholder="请填写活动名称"></el-input>
        </el-form-item>
        <el-form-item label="排序：">
          <el-input v-model="edit.sort" placeholder="请输入排序数" type="number"></el-input>
          <span class="tips">排序号越小，排序位置越前，首图可增加曝光机会</span>
        </el-form-item>
        <el-form-item label="起止日期：" prop="date">
          <el-date-picker
            v-model="edit.date"
            type="datetimerange"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item label="添加banner：" prop="img">
          <Upload id="banner" src="activity" @success="onUpload">
            <div class="logo" v-if="edit.img.url">
              <img :src="edit.img.url" alt="img" width="100%">
            </div>
            <div class="upload-box flex-center" v-else>
              <i class="el-icon-plus"></i>
              <span class="text">点击上传</span>
            </div>
          </Upload>
          <span class="tips">建议图片尺寸1125x1038像素，且大小 &lt;=500KB，支持jpg、png格式，最多5张</span>
        </el-form-item>
        <el-form-item label="跳转类型：" prop="jump">
          <el-select v-model="edit.jump" placeholder="请选择">
            <el-option
              v-for="item in jumpOptions"
              :key="item.type"
              :label="item.name"
              :value="item.type">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="跳转参数：">
          <el-input v-model="edit.link"></el-input>
        </el-form-item>
        <el-form-item label="活动状态：">
          <el-radio-group v-model="edit.status">
            <el-radio :label="1">生效</el-radio>
            <el-radio :label="0">未生效</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onConfirm">确定</el-button>
          <el-button @click="onCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="demo">
      <h3 class="title">首页顶部banner示例</h3>
      <img src="https://ddimage.topboom0912.com/admin_static_img/demo/index_topbanner.png" alt="demo" width="298px">
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import Upload from '@/components/Upload'
export default {
  name: 'HomeBannerAdd',
  components: {
    Upload
  },
  data() {
    return {
      id: null,
      detail: null,
      edit: {
        name: '',
        sort: '',
        link: '',
        img: {
          w: 100,
          h: 100,
          url: ''
        },
        jump: '',
        date: null,
        status: 1
      },
      rules: {
        name: [{required: true, message: '请输入名称'}],
        date: [{required: true, message: '请选择时间'}],
        img: [{required: true, message: '请上传banner'}],
        jump: [{required: true, message: '请选择跳转类型'}]
      },
      tableData: []
    }
  },
  computed: mapState([
    'jumpOptions'
  ]),
  mounted() {
    this.id = this.$route.query.id * 1 || null
    if (this.id) {
      this.getDetail()
    }
  },
  methods: {
    async getDetail() {
      const url = 'admin/activity/detail?id=' + this.id
      const data = await this.$https.get(url)
      if (data) {
        this.edit.name = data.activity_name
        this.edit.sort = data.sort
        this.edit.link = data.external_params
        this.edit.date = [data.start_time, data.end_time]
        this.edit.status = data.status
        this.edit.img.url = this.$img(data.img)
        this.edit.jump = data.jump_type
        this.detail = data
      }
    },
    onUpload(url) {
      this.edit.img.url = url
    },
    async onConfirm () {
      const rule = await new Promise(resolve => {
        this.$refs.ruleForm.validate((valid) => {
          resolve(valid)
        })
      })
      if (!rule) return
      const {name, sort, jump, date, status, link, img} = this.edit
      if (!img.url) {
        this.$message.error('请上传banner')
        return
      }
      const url = 'admin/activity/edit'
      let params = {
        position_type: 1,
        activity_name: name,
        start_time: date[0],
        end_time: date[1],
        jump_type: jump,
        status,
        external_params: link,
        sort,
        img: JSON.stringify(img)
      }
      if (this.detail) params = Object.assign({}, this.detail, params)
      this.$https.post(url, params).then(data => {
        if (data) {
          this.$message.success(this.id ? '编辑成功' : '添加成功')
          this.edit.name = ''
          this.edit.link = ''
          this.edit.date = null
          this.edit.status = 1
          this.edit.img.url = ''
          this.$router.push('/homebanner/list')
        }
      })
    },
    onCancel() {
      this.$router.push('/homebanner/list')
    }
  }
}
</script>

<style scoped lang="scss">
.edit {
  padding-right: 70px;
  border-right: 1px solid #E8E8E8;
  box-sizing: border-box;
  .tb-form {
    width: 640px;
    .tips {
      padding-top: 8px;
      color: rgba(0, 0, 0, 0.45);
    }
    .demo-text {
      color: #1890FF;
      cursor: pointer;
    }
    .upload {
      display: flex;
      flex-direction: column;
      line-height: 1;
      &-box {
        width: 375px;
        height: 346px;
        flex-direction: column;
        border-radius: 4px;
        border: 1px dashed rgba(0, 0, 0, 0.15);
        cursor: pointer;
        .text {
          padding-top: 8px;
          color: rgba(0, 0, 0, 0.65);
        }
      }
      .logo {
        position: relative;
        width: 375px;
        height: 346px;
        overflow: hidden;
        cursor: pointer;
      }
    }
  }
}
.demo {
  padding-left: 70px;
  .title {
    margin: 0;
    padding-bottom: 16px;
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
  }
}
</style>
